import React from 'react';
import { Box } from '@mui/material';
import __ from '../../utils/i18n';

const styles = {
    box: {
        padding: 20,
        overflowY: 'auto',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        wordBreak: 'break-all',
    },
    title: {
        fontSize: 22,
        display: 'block',
        fontWeight: 'bold',
        marginBottom: 16,
    },
    title2: {
        fontSize: 18,
        display: 'block',
        fontWeight: 'bold',
        marginBottom: 16,
        marginTop: 24,
    },
    title3: {
        fontSize: 16,
        display: 'block',
        fontWeight: 'bold',
        marginBottom: 8,
        marginTop: 16,
    },
    title4: {
        fontSize: 14,
        display: 'block',
        fontWeight: 'bold',
        marginBottom: 8,
        marginTop: 16,
    },
    text: {},
};

export default function Contract() {
    return (
        <Box style={styles.box}>
            <div style={styles.title}>{__('Contract.')}</div>
            <div style={styles.title2}>Lizenzvertrag/AGB</div>
            <div style={styles.text}>
                Dieser Lizenzvertrag gilt zwischen Ihnen (Endnutzer, Lizenznehmer) und dem Hersteller der Software. Die
                Software umfasst das Software-Programm, deren elektronische Dokumentation und (soweit vorhanden)
                Datenträger.
                <br />
                <br />
                Nach dem heutigen Stand der Technik ist es nicht möglich, Software zu erstellen, die in allen
                Anwendungsfällen fehlerfrei arbeitet. Gegenstand dieses Vertrages ist deswegen nur eine Software, die im
                Sinne der Programmbeschreibung grundsätzlich brauchbar ist.
                <br />
                <br />
                Bitte lesen Sie die nachfolgenden Informationen sorgfältig, bevor Sie diese Software installieren,
                benutzen, lizenzieren oder weitergeben. Durch Installation, Benutzung, Lizenzierung oder Weitergabe der
                Software erklären Sie Ihr Einverständnis mit diesem Lizenzvertrag.
                <br />
                <br />
                Falls Sie den Bestimmungen dieses Vertrags nicht zustimmen, sind Sie nicht berechtigt, die Software zu
                installieren, zu benutzen, zu lizenzieren oder weiterzugeben.
            </div>
            <div style={styles.title2}>
                1. Nutzungsrecht
                <br />
            </div>
            <div style={styles.text}>Es existieren zwei Lizenztypen: Kommerzielle Software und Freeware.</div>
            <div style={styles.title3}>1.1 Kommerzielle Software</div>

            <div style={styles.title4}>1.1.1 Lizenzierte Vollversion</div>
            <div style={styles.text}>
                Durch Erwerb einer kommerziellen Nutzungslizenz erhält der Endbenutzer die lizenzierte Vollversion und
                das Recht, die Software sowie alle Updates, die innerhalb von einer Majorversion nach der Lizenzierung
                erscheinen, gemäß diesen Bestimmungen unbefristet zu nutzen. Eine Verletzung des Vertrages durch den
                Benutzer führt zum Erlöschen des Nutzungsrechts.
                <br />
                <br />
                Eine einzelne Lizenz kann von einer einzelnen Person auf einem ioBroker Server verwendet werden.
                <br />
                <br />
                Arbeiten mehrere Personen auf unterschiedlichen Rechnern mit der Software, so ist für jeden Rechner auf
                dem das Programm installiert oder verwendet werden kann, eine Lizenz notwendig.
                <br />
                <br />
                Bei einigen Produkten erfolgt eine Unterscheidung in Privatlizenz und kommerzielle Lizenz. Bei diesen
                sind zusätzlich folgende Punkte zu beachten:
            </div>
            <ul>
                <li>
                    Privatlizenzen dürfen nur im privaten, nichtkommerziellen Bereich von Privatpersonen eingesetzt
                    werden.
                </li>
                <li>
                    Der Einsatz der Software in einem Unternehmen, einer Behörde, einem Verein, einer Stiftung oder
                    durch einen Selbstständigen oder Gewerbetreibenden bedingt eine kommerzielle Lizenz.
                </li>
            </ul>
            <div style={styles.text}>
                Der Hersteller bietet für kommerzielle Lizenz ab Lizenzierung mind. 12 Monate kostenlosen Support per
                E-Mail oder im Forum.
            </div>
            <div style={styles.title3}>1.2 Freeware-Software</div>

            <div style={styles.title4}>1.2.1 Nichtkommerzielle Nutzung</div>
            <div style={styles.text}>
                Freeware-Software darf im privaten, nichtkommerziellen Bereich von Privatpersonen (bis auf Widerruf)
                kostenlos verwendet werden.
                <br />
                Ist eine kommerzielle Lizenz für die Software verfügbar, so kann der Privatanwender diese bei Bedarf
                lizenzieren. Für die kommerzielle Versionen gelten die Bestimmungen unter 1.1.1.
                <br />
            </div>
            <div style={styles.title4}>1.2.2 Kommerzielle Nutzung</div>
            <div style={styles.text}>
                Für den Einsatz von Freeware-Software in einem Unternehmen, einer Behörde, einem Verein, einer Stiftung
                oder durch einen Selbstständigen oder Gewerbetreibenden ist (sofern verfügbar) die kommerzielle Version
                der entsprechenden Software zu erwerben. Für diese gelten die Bestimmungen unter 1.1.1.
                <br />
                Ist keine kommerzielle Version der Software verfügbar, so hat der Anwender vor Nutzung Kontakt zum
                Hersteller aufzunehmen um ein Nutzungsrecht individuell zu vereinbaren.
                <br />
            </div>
            <div style={styles.title3}>1.3 Einschränkungen</div>
            <ul>
                <li>
                    Veränderungen, Dekompilierung und Disassemblierung der Software sind nicht erlaubt und werden zur
                    Anzeige gebracht.
                </li>
                <li>
                    Entwicklung, Weitergabe und Nutzung von Methoden, die dem Ziel dienen, die Einschränkungen der
                    Testversion zu entfernen sowie die Weitergabe der Vollversion oder der Lizenzdaten sind nicht
                    zulässig und werden zur Anzeige gebracht.
                </li>
                <li>Verschenken, Verleih, Vermietung und Verleasung der Software sind nicht zulässig.</li>
            </ul>
            <div style={styles.title2}>2. Urheberrecht</div>
            <div style={styles.text}>
                Die Software, die Dokumentation sowie die Programm- und Datenkonzeption sind urheberrechtlich geschützt.
            </div>
            <div style={styles.title2}>3. Pflichten und Haftung des Lizenznehmers</div>
            <div style={styles.text}>Sie sind verpflichtet</div>
            <ul style={styles.text}>
                <li>
                    sicherzustellen, dass die lizenzierte Vollversion sowie die Lizenzdaten nicht in die Hände von
                    Dritten gelangen. Bei Zuwiderhandlung ist ein pauschaler Schadensersatz in Höhe von 1000 Euro zu
                    entrichten. Sind höhere Schäden entstanden, bleiben ein darüber hinausgehender Schadensersatz und
                    strafrechtliche Schritte vorbehalten.
                </li>
                <li>
                    die mit der Software erstellten Ergebnisse (z.B. Datensicherungen bei Datensicherungssoftware)
                    unmittelbar nach Erstellung auf Vollständigkeit und Fehlerfreiheit zu überprüfen.
                </li>
                <li>
                    die mit der Software erstellten Ergebnisse und Daten regelmäßig zu sichern und die
                    Funktionsfähigkeit der Datensicherung zu kontrollieren.
                </li>
            </ul>
        </Box>
    );
}
