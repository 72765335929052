import React from 'react';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/browser';

import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// if not local development
if (window.location.host !== 'localhost:3000') {
    Sentry.init({
        dsn: 'https://ae7c2b6f363a40c5b975bf9785f6f8f2@sentry.iobroker.net/120',
        release: 'iobroker.cloud-gui@2021_02_23',
        integrations: [
            Sentry.dedupeIntegration(),
        ],
    });
}

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(<SnackbarProvider maxSnack={3}>
        <App />
    </SnackbarProvider>);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();

reportWebVitals(console.log);
