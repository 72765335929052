import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';

import __ from '../../utils/i18n';
import Contract from '../Contract';
import { apiAckContract } from '../../api';
import MainContext from '../../MainContext';

export default function ContractDialog(props) {
    const context = React.useContext(MainContext);

    return (
        <Dialog
            open={!0}
            onClose={() => props.onClose()}
            aria-labelledby="contract-dialog-title"
            aria-describedby="contract-dialog-description"
        >
            <DialogTitle id="contract-dialog-title">{__('Terms')}</DialogTitle>
            <DialogContent>
                <Contract />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    autoFocus
                    onClick={() => props.onClose(false)}
                    startIcon={<CloseIcon />}
                >
                    {__('Disagree')}
                </Button>
                <Button
                    variant="contained"
                    startIcon={<CheckIcon />}
                    onClick={() =>
                        apiAckContract()
                            .then(() => context.updateUser(true))
                            .then(() => props.onClose(true))
                            .catch(error =>
                                context.snackbar(__('Cannot send acknowledge: %s', __(error)), 'error', true),
                            )
                    }
                    color="primary"
                    autoFocus
                >
                    {__('Agree')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
