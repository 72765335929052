import React from 'react';
import { useNavigate } from 'react-router-dom';

import MainContext from '../../MainContext';
import __ from '../../utils/i18n';

export default function Logout() {
    const context = React.useContext(MainContext);
    const navigate = useNavigate();

    // if user information received
    if (context.user !== null) {
        setTimeout(() => {
            if (context.user) {
                context.logoutUser().then(() => navigate('/www/login'));
            } else {
                navigate('/www/login');
            }
        }, 300);
    }

    return __('Logging out...');
}
