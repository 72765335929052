import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Input,
    MenuItem,
    Select,
    Grid,
    Divider,
    FormControlLabel,
    Checkbox,
} from '@mui/material';

import PasswordInput from '../../../utils/PasswordInput';
import __ from '../../../utils/i18n';
import { MOBILE_WIDTH } from '../../../utils/consts';
import { apiChangePassword, apiUpdateUser, apiChangeEmail } from '../../../api';
import MainContext from '../../../MainContext';

const styles = {
    formControl: {
        margin: 8,
        minWidth: 120,
    },
    root22: {
        overflowX: 'hidden',
        overflowY: 'auto',
        justifyContent: 'center',
    },
    columns: {
        overflowX: 'hidden',
        maxWidth: 700,
        flexWrap: 'nowrap',
    },
    boxed: {
        padding: window.document.body.clientWidth >= MOBILE_WIDTH ? 20 : 3,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 500,
        width: window.document.body.clientWidth >= MOBILE_WIDTH ? 'calc(100% - 40px)' : 'calc(100% - 6px)',
    },
    btn: {
        marginTop: 10,
    },
    divider: {
        width: '100%',
        margin: '20px 0',
    },
    label: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
    },
};

function Profile(props) {
    const context = React.useContext(MainContext);

    const [updated, setUpdated] = React.useState(false);
    const [language, setLanguage] = React.useState('');
    const [subscription, setSubscription] = React.useState('');

    if (!updated) {
        setUpdated(true);
        context
            .updateUser(true)
            .then(user => {
                setLanguage(user.lang);
                setSubscription(user.newsletter);
            })
            .catch(err => console.warn(`Cannot read user: ${err}`));
    }

    const [password, setPassword] = React.useState('');
    const [passwordConfirm, setPasswordConfirm] = React.useState('');
    const [oldPassword, setOldPassword] = React.useState('');
    const handleChangePassword = (/* event */) => {
        if (password !== passwordConfirm) {
            context.snackbar('Passwords are not same', 'error');
            return;
        }

        apiChangePassword(oldPassword, password)
            .then(result => {
                setPassword('');
                setPasswordConfirm('');
                setOldPassword('');
                if (result.secondCloud) {
                    context.snackbar('Password changed on both clouds', 'success');
                } else {
                    context.snackbar('Password changed', 'success');
                }
            })
            .catch(error => context.snackbar(__('Cannot change password: %s', __(error)), 'error', true));
    };

    const [email, setEmail] = React.useState('');
    const [currentPasswordForEmail, setCurrentPasswordForEmail] = React.useState('');
    const [changeAllEmails, setChangeAllEmails] = React.useState(true);
    const handleEmail = event => setEmail(event.target.value);
    const handleCurrentPasswordForEmail = event => setCurrentPasswordForEmail(event.target.value);
    const handleChangeAllEmails = event => setChangeAllEmails(event.target.value);
    const handleSendConfirmationEmail = (/* result, error */) => {
        apiChangeEmail(email, currentPasswordForEmail, changeAllEmails)
            .then(() => context.snackbar('Email with instructions was sent to new address', 'success'))
            .catch(error => context.snackbar(__('Cannot change email: %s', __(error)), 'error', true))
            .then(() => setCurrentPasswordForEmail(''));
    };

    const handleLanguage = event => setLanguage(event.target.value);

    const handleChangeLanguage = () =>
        apiUpdateUser({ lang: language })
            .then(result => {
                context.updateUser(true).catch(err => console.warn(`Cannot read user: ${err}`));

                if (result.secondCloud) {
                    context.snackbar('language was changed on both clouds', 'success');
                } else {
                    context.snackbar('language changed', 'success');
                }
            })
            .catch(error => context.snackbar(__('Cannot change language: %s', __(error)), 'success', true));

    if (!context.user) {
        return null;
    }

    const handleChangeSubscription = () =>
        apiUpdateUser({ newsletter: subscription })
            .then(result => {
                context.updateUser(true).catch(err => console.warn(`Cannot read user: ${err}`));

                if (result.secondCloud) {
                    context.snackbar('Changed user subscription on both clouds', 'success');
                } else {
                    context.snackbar('Changed user subscription', 'success');
                }
            })
            .catch(error => context.snackbar(__('Cannot change user: %s', __(error)), 'success', true));

    return (
        <Grid
            sx={theme => ({
                ...theme.container[props.style],
                padding: window.document.body.clientWidth >= MOBILE_WIDTH ? '10px 50px' : '5px 5px',
            })}
        >
            <Box
                key="Change subscription"
                variant="outlined"
                style={styles.boxed}
            >
                <Box sx={theme => theme.containerTitle[props.style]}>{__('Change subscription')}</Box>

                <FormControl
                    style={styles.formControl}
                    variant="standard"
                >
                    <InputLabel id="subscription-select-label">{__('Subscription')}</InputLabel>
                    <Select
                        variant="standard"
                        labelId="subscription-select-label"
                        id="subscription-select"
                        value={subscription}
                        onChange={event => setSubscription(event.target.value)}
                    >
                        <MenuItem value={1}>{__('Receive newsletters')}</MenuItem>
                        <MenuItem value={2}>{__('No newsletters')}</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    disabled={!context.user || subscription === context.user.newsletter}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleChangeSubscription}
                >
                    {__('Change Subscription')}
                </Button>
            </Box>

            <Divider style={styles.divider} />
            <Box
                key="Change language"
                variant="outlined"
                style={styles.boxed}
            >
                <Box sx={theme => theme.containerTitle[props.style]}>{__('Change language')}</Box>
                <FormControl
                    style={styles.formControl}
                    variant="standard"
                >
                    <InputLabel id="language-select-label">{__('Language')}</InputLabel>
                    <Select
                        variant="standard"
                        labelId="language-select-label"
                        id="language-select"
                        value={language}
                        onChange={handleLanguage}
                    >
                        <MenuItem value="de">Deutsch</MenuItem>
                        <MenuItem value="ru">Русский</MenuItem>
                        <MenuItem value="en">English</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    disabled={!context.user || language === context.user.lang}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleChangeLanguage}
                >
                    {__('Change Language')}
                </Button>
            </Box>

            <Divider style={styles.divider} />
            <Box
                key="Change password"
                variant="outlined"
                style={styles.boxed}
            >
                <Box sx={theme => theme.containerTitle[props.style]}>{__('Change password')}</Box>
                <PasswordInput
                    check={false}
                    id="currentPassword"
                    label="Current Password"
                    describedBy="currentPasswordText"
                    value={oldPassword}
                    onChange={value => setOldPassword(value)}
                />
                <PasswordInput
                    check
                    id="newPassword"
                    label="New Password"
                    describedBy="newPasswordText"
                    value={password}
                    onChange={value => setPassword(value)}
                />
                <PasswordInput
                    check={false}
                    id="passwordRepeat"
                    label="Password Repeat"
                    describedBy="passwordRepeatText"
                    value={passwordConfirm}
                    onChange={value => setPasswordConfirm(value)}
                />
                <Button
                    disabled={!passwordConfirm || !password || !oldPassword}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={styles.btn}
                    onClick={handleChangePassword}
                >
                    {__('Change Password')}
                </Button>
            </Box>

            <Divider style={styles.divider} />
            <Box
                key="Change email"
                variant="outlined"
                style={styles.boxed}
            >
                <Box sx={theme => theme.containerTitle[props.style]}>{__('Change email')}</Box>
                <FormControl variant="standard">
                    <InputLabel htmlFor="email">{__('Email')}</InputLabel>
                    <Input
                        id="email"
                        placeholder={__('Enter new email address')}
                        aria-describedby="emailText"
                        value={email}
                        inputProps={{
                            autoComplete: 'new-password',
                            form: { autoComplete: 'off' },
                        }}
                        onChange={handleEmail}
                    />
                </FormControl>
                <FormControl variant="standard">
                    <InputLabel htmlFor="currentPasswordForEmail">{__('Current password')}</InputLabel>
                    <Input
                        id="currentPasswordForEmail"
                        placeholder={__('Enter current password')}
                        aria-describedby="currentPasswordForEmail"
                        value={currentPasswordForEmail}
                        type="password"
                        inputProps={{
                            autoComplete: 'new-password',
                            form: { autoComplete: 'off' },
                        }}
                        onChange={handleCurrentPasswordForEmail}
                    />
                </FormControl>
                <FormControl variant="standard">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={changeAllEmails}
                                onChange={handleChangeAllEmails}
                            />
                        }
                        label={__('Change on all clouds (pro/net)')}
                    />
                </FormControl>
                <Button
                    variant="contained"
                    disabled={!email || !currentPasswordForEmail}
                    color="primary"
                    size="small"
                    style={styles.btn}
                    onClick={handleSendConfirmationEmail}
                >
                    {__('Send Email with instructions')}
                </Button>
                {/* <p style={styles.label}>{__('To change the email, please contact support')} <a href="mailto:info@iobroker.net" rel="noreferrer" target="_blank">info@iobroker.net</a></p> */}
            </Box>

            <Divider style={styles.divider} />
            <Box
                key="Delete Profile"
                variant="outlined"
                style={styles.boxed}
            >
                <Button
                    component={RouterLink}
                    to="/www/deleteProfile"
                    variant="contained"
                    size="small"
                    color="secondary"
                >
                    {__('Delete Profile')}
                </Button>
            </Box>
        </Grid>
    );
}

export default Profile;
