import React from 'react';
import { Navigate } from 'react-router';
import { Route, Routes, useNavigate } from 'react-router-dom';

import {
    Container,
    Grid,
    Card,
    Drawer,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Box,
} from '@mui/material';

import { Check as IconCheck, Close as IconClose } from '@mui/icons-material';

import './style.css';
import { routesExtended, menuMain } from '../App';
import BottomMenu from './BottomMenu';
import MainContext from '../MainContext';
import { MOBILE_WIDTH } from '../utils/consts';
import TopMenu from './TopMenu';
import Intro from './Intro';
import Account from './Account';
import LeftMenu from './Account/LeftMenu';
import { apiUpdateUser, apiSendConfirmationEmail } from '../api';
import __ from '../utils/i18n';

const drawerWidth = 220;

const styles = {
    root2: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'stretch',
        justifyContent: 'stretch',
    },
    root3: {
        display: 'flex',
        padding: 0,
        '@media (min-width: 1400px)': {
            maxWidth: 1400,
        },
    },
    container: {
        height: '100%',
    },
    top: {
        height: 54,
    },
    cont: {
        flexGrow: 1,
        height: 'calc(100% - 64px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
    },
    menuButton: {
        marginRight: 16,
    },
    hide: {
        display: 'none',
    },
    drawerHeader: {
        position: 'absolute',
        zIndex: 10,
        left: 0,
        top: 0,
    },
};

export default function Pages() {
    const context = React.useContext(MainContext);
    const navigate = useNavigate();

    const [showConfirmEmail, setShowConfirmEmail] = React.useState(true);
    const [open, setOpen] = React.useState(window.document.body.clientWidth >= MOBILE_WIDTH);

    const handleLogout = () => {
        context
            .logoutUser()
            .then(() => context.snackbar('You are logged out', 'success'))
            .then(() => navigate('/www/'))
            .catch(e => context.snackbar(__('Cannot logout: %s', __(e)), 'error', true));
    };

    const realOpen = !!(open && context.user) && window.location.pathname !== '/www/logout';
    const style = context.theme;

    const handleChangeSubscription = subscription =>
        apiUpdateUser({ newsletter: subscription })
            .then(() => {
                context.updateUser(true).catch(err => console.warn(`Cannot read user: ${err}`));
                context.snackbar('Changed user subscription', 'success');
            })
            .catch(error => context.snackbar(__('Cannot change user: %s', __(error)), 'success', true));

    function sendConfirmationEmail() {
        apiSendConfirmationEmail()
            .then(() => {
                setShowConfirmEmail(false);
                context.snackbar('Confirmation email sent', 'success');
            })
            .catch(error => context.snackbar(__('Cannot send email: %s', error), 'error', true));
    }

    const informAboutEmailConfirm =
        context.user && context.user.emailAck === false && showConfirmEmail ? (
            <Dialog
                open={!0}
                onClose={() => {}}
                aria-labelledby="email-confirm-dialog-title"
                aria-describedby="email-confirm-dialog-description"
            >
                <DialogTitle id="email-confirm-dialog-title">{__('Email is not yet confirmed.')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="email-confirm-dialog-description">
                        {__('You cannot do anything till email is confirmed.')}
                        <br />
                        {__(
                            'On your email address was sent the confirmation link. Please click on it to confirm your email address.',
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => sendConfirmationEmail()}
                    >
                        {__('Send confirmation email one more time')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => setShowConfirmEmail(false)}
                        color="primary"
                        autoFocus
                    >
                        {__('I will check the emails')}
                    </Button>
                </DialogActions>
            </Dialog>
        ) : null;

    const confirmGDPRDialog =
        !informAboutEmailConfirm && context.user && context.user.userId && !context.user.newsletter ? (
            <Dialog
                open={!0}
                onClose={() => {}}
                aria-labelledby="newsletter-dialog-title"
                aria-describedby="newsletter-dialog-description"
            >
                <DialogTitle id="newsletter-dialog-title">{__('Subscribe on newsletter')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="newsletter-dialog-description">
                        {__('Receive newsletter according to GDPR.')}
                        <br />
                        {__('We send only important information.')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => handleChangeSubscription(2)}
                        startIcon={<IconClose />}
                    >
                        {__("I don't need any newsletter")}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleChangeSubscription(1)}
                        color="primary"
                        autoFocus
                        startIcon={<IconCheck />}
                    >
                        {__('Subscribe')}
                    </Button>
                </DialogActions>
            </Dialog>
        ) : null;

    const routes = Object.keys(menuMain)
        .map((e, i) => {
            const Component = menuMain[e].component;
            if (!Component) {
                return null;
            }
            return (
                <Route
                    path={`/www/${e}`}
                    key={i}
                    element={<Component style={style} />}
                />
            );
        })
        .concat(
            Object.keys(routesExtended).map((route, i) => {
                const Component = routesExtended[route].component;
                return (
                    <Route
                        path={`/www/${route}`}
                        key={i}
                        element={<Component style={style} />}
                    />
                );
            }),
        );

    return (
        <Container
            sx={styles.root3}
            id="AAA"
        >
            {confirmGDPRDialog}
            {informAboutEmailConfirm}
            <Card
                className="main"
                style={styles.root2}
                raised
            >
                <Grid
                    container
                    alignContent="center"
                    direction="row"
                    style={styles.container}
                >
                    <Grid
                        item
                        xs={12}
                        style={styles.top}
                    >
                        <TopMenu
                            style={style}
                            handleDrawer={() => setOpen(!open)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={styles.cont}
                    >
                        {context.user ? (
                            <Drawer
                                className="drawer"
                                variant="persistent"
                                anchor="left"
                                open={realOpen}
                                sx={theme => ({
                                    width: realOpen ? drawerWidth : 0,
                                    overflow: 'hidden',
                                    height: 'calc(100% - 41px)',
                                    position: 'absolute',
                                    transition: theme.transitions.create(['width'], {
                                        easing:
                                            realOpen && window.document.body.clientWidth >= MOBILE_WIDTH
                                                ? theme.transitions.easing.sharp
                                                : theme.transitions.easing.easeOut,
                                        duration: '300ms',
                                    }),
                                    '& .MuiDrawer-paper': {
                                        position: 'relative',
                                        transition: 'none !important',
                                    },
                                })}
                            >
                                <LeftMenu
                                    style={style}
                                    handleLogout={handleLogout}
                                    onMenuClose={() => setOpen(false)}
                                />
                            </Drawer>
                        ) : null}
                        <Box
                            id="box"
                            sx={theme => ({
                                padding: 0,
                                height: 'calc(100% - 41px)',
                                overflowY: 'hidden',
                                display: 'flex',
                                alignItems: window.location.pathname.endsWith('login') ? 'center' : 'stretch',
                                position: 'relative',
                                ...theme.content[context.theme],
                                flexGrow: realOpen && window.document.body.clientWidth >= MOBILE_WIDTH ? 1 : undefined,
                                transition: theme.transitions.create(['margin', 'transform'], {
                                    easing:
                                        realOpen && window.document.body.clientWidth >= MOBILE_WIDTH
                                            ? theme.transitions.easing.sharp
                                            : theme.transitions.easing.easeOut,
                                    duration: '300ms',
                                }),
                                ml:
                                    realOpen && window.document.body.clientWidth >= MOBILE_WIDTH
                                        ? `${drawerWidth}px`
                                        : 0,
                            })}
                        >
                            <Routes>
                                <Route
                                    path="/www"
                                    element={<Intro style={style} />}
                                />
                                <Route
                                    path="/www/account/*"
                                    element={<Account style={style} />}
                                />
                                {routes}
                                <Route
                                    path="/www/account"
                                    element={
                                        <Navigate
                                            to={
                                                context.theme === 'pro'
                                                    ? '/www/account/subscriptions'
                                                    : '/www/account/licenses'
                                            }
                                        />
                                    }
                                />
                            </Routes>
                        </Box>
                        <BottomMenu
                            style={style}
                            menuOpened={realOpen}
                            showOpenButton={context.user && context.user.userId}
                            onMenuOpen={() => setOpen(true)}
                        />
                    </Grid>
                </Grid>
            </Card>
        </Container>
    );
}
