import React from 'react';

import { Box, Typography } from '@mui/material';

import __ from '../../utils/i18n';
import { MOBILE_WIDTH } from '../../utils/consts';

const styles = {
    tabs: {
        background: 'white',
    },
    space: {
        marginLeft: 8,
    },
};

export default function Imprint(props) {
    return (
        <Box
            sx={theme => ({
                ...theme.container[props.style],
                padding: window.document.body.clientWidth >= MOBILE_WIDTH ? '10px 50px' : '5px 5px',
                width: '100%',
            })}
        >
            <Typography sx={theme => theme.containerTitle[props.style]}>{__('Imprint')}</Typography>
            <Typography
                variant="body1"
                component="div"
            >
                <Typography paragraph>
                    ioBroker GmbH
                    <br />
                    Talstr. 27
                    <br />
                    67317 Altleiningen
                    <br />
                    {__('Germany')}
                </Typography>
                <Typography paragraph>
                    {__('E-Mail')}:<span style={styles.space}>info@iobroker.net</span>
                </Typography>
                <Typography paragraph>
                    {__('CEO')}:<span style={styles.space}>Denis Haev</span>
                    <br />
                    {__('Register court: Ludwigshafen a.Rhein')}
                    <br />
                    {__('Registration number')}:<span style={styles.space}>HRB 68933</span>
                </Typography>
                <Typography paragraph>
                    {__('WEEE-Reg.-Nr.')}:<span style={styles.space}>DE352273606</span>
                </Typography>
                <Typography paragraph>{__('Responsible for content: Denis Haev (Address see above)')}</Typography>
            </Typography>
        </Box>
    );
}
