import React from 'react';
import { FormControl, TextField, Checkbox, Grid2 as Grid, Button } from '@mui/material';

import MainContext from '../../MainContext';
import { apiLogin, apiRegister } from '../../api';
import PasswordInput from '../../utils/PasswordInput';
import __, { getLang } from '../../utils/i18n';

const styles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
    },
    margin: {
        margin: 8,
    },
    withoutLabel: {
        marginTop: 24,
    },
    textField: {
        width: '30ch',
    },
    label: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 11,
    },
    link: {
        color: '#000',
        marginLeft: 4,
    },
    spaces: {
        marginLeft: 4,
        marginRight: 4,
    },
};

export default function CreateAccountForm(props) {
    const { onChangeTab } = props;
    const context = React.useContext(MainContext);
    const textInput = React.useRef(null);

    const [values, setValues] = React.useState({
        email: '',
        password: '',
        showPassword: false,
        passwordError: false,
        privacyAck: false,
    });

    if (context.user) {
        return null;
    }

    const handleChange = prop => event => {
        if (event.target.type === 'checkbox') {
            setValues({ ...values, [prop]: event.target.checked });
        } else {
            setValues({ ...values, [prop]: event.target.value });
        }
    };

    const handleSignUp = () =>
        apiRegister(values.email, values.password, values.privacyAck)
            .then(() => {
                context.snackbar('User registered', 'success');
                return apiLogin(values.email, values.password, false);
            })
            .then(() => context.updateUser(true)) // redirect will be done in Login/index.js
            .catch(e => {
                if (e === 'User already exists') {
                    // change tab
                    onChangeTab && onChangeTab(values.email, values.password);
                }
                context.snackbar(__('Cannot register: %s', __(e)), 'error', true);
            });

    const handleEnter = event => {
        if (event.key === 'Enter') {
            console.log(textInput);
            textInput.current.focus();
            event.preventDefault();
        }
    };

    return (
        <div style={styles.root}>
            <Grid
                container
                direction="column"
                alignContent="center"
                alignItems="center"
            >
                <form style={styles.form}>
                    <TextField
                        id="signup-login"
                        variant="standard"
                        placeholder={__('login')}
                        slotProps={{
                            input: {
                                autoComplete: 'new-password',
                            },
                            htmlInput: {
                                autoComplete: 'off',
                            },
                        }}
                        style={{
                            ...styles.margin,
                            ...styles.textField,
                        }}
                        value={values.email}
                        onChange={handleChange('email')}
                        onKeyDown={handleEnter}
                    />
                    <PasswordInput
                        style={{
                            ...styles.margin,
                            ...styles.textField,
                        }}
                        check
                        id="signup-password"
                        placeholder={__('password')}
                        describedBy="signupPasswordText"
                        value={values.password}
                        onKeyDown={e => e.key === 'Enter' && values.privacyAck && handleSignUp()}
                        onChange={(password, passwordError) => setValues({ ...values, password, passwordError })}
                    />
                    <FormControl
                        style={{ display: 'block' }}
                        variant="standard"
                    >
                        <Checkbox
                            checked={values.privacyAck}
                            onChange={handleChange('privacyAck')}
                            onKeyDown={handleEnter}
                            inputRef={textInput}
                            id="privacyAckCreate"
                        />
                        <label
                            htmlFor="privacyAckCreate"
                            style={styles.label}
                            onClick={() => setValues({ ...values, privacyAck: !values.privacyAck })}
                        >
                            {getLang() === 'de' ? (
                                <span>
                                    Ich bin mit den
                                    <a
                                        href="/www/policy"
                                        target="_blank"
                                        style={styles.spaces}
                                    >
                                        Datenschutz-Bestimmungen
                                    </a>
                                    einverstanden
                                </span>
                            ) : (
                                ''
                            )}
                            {getLang() !== 'de' ? (
                                <span>
                                    {__('I agree with')}
                                    <a
                                        href="/www/policy"
                                        style={styles.link}
                                        target="_blank"
                                    >
                                        {__('privacy policy')}
                                    </a>
                                </span>
                            ) : null}
                        </label>
                    </FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleSignUp}
                        disabled={!(values.email && values.password && values.privacyAck && !values.passwordError)}
                    >
                        {__('Sign Up')}
                    </Button>
                </form>
            </Grid>
        </div>
    );
}
