import React from 'react';
import CreateAccountForm from './CreateAccountForm';

export default function CreateAccountFormTab(props) {
    const { index, onChangeTab } = props;

    return (
        <div
            role="tabpanel"
            id="simple-tabpanel-1"
            aria-labelledby="simple-tab-1"
            hidden={index !== 1}
        >
            <CreateAccountForm onChangeTab={(email, password) => onChangeTab && onChangeTab(email, password)} />
        </div>
    );
}
