import React, { useEffect, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import { Menu, Button, Grid, ListItemText, ListItemIcon, MenuItem } from '@mui/material';

import { Menu as IconMenu, Person as IconPerson, Language as IconLanguage } from '@mui/icons-material';

import MainContext from '../../MainContext';
import { menuMain } from '../../App';
import { MOBILE_WIDTH, SMALL_MOBILE_WIDTH } from '../../utils/consts';

import logoNet from '../../assets/img/logo_net.svg';
import logoPro from '../../assets/img/logo_pro.svg';
import logoNetSmall from '../../assets/img/logo_net_small.svg';
import logoProSmall from '../../assets/img/logo_pro_small.svg';
import __ from '../../utils/i18n';

const styles = {
    title: {
        flexGrow: 1,
    },
    hamburger: {
        display: 'flex',
    },
    toolbar: {
        zIndex: 1201,
        padding: 0,
    },
    avatar: {
        marginLeft: 10,
    },
    menuButton: {
        height: 54,
        fontSize: '1rem',
    },
    language: {
        textTransform: 'capitalize',
        color: '#1b4777',
        fontSize: 16,
    },
    buttonIcon: {
        marginRight: 3,
        width: 32,
        height: 32,
    },
};

export default function TopMenu(props) {
    const context = React.useContext(MainContext);

    const [width, setWidth] = React.useState(1000);

    const updateWindowDimensions = () => setWidth(document.body.clientWidth);

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
        updateWindowDimensions();

        // returned function will be called on component unmount
        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, []);

    const logoHeight = 41;
    const logoWidth = width < SMALL_MOBILE_WIDTH ? 41 : 191;

    /* mobile menu */
    const [_anchorEl, _setAnchorEl] = React.useState(null);
    const _handleClick = event => _setAnchorEl(event.currentTarget);
    const _handleClose = () => _setAnchorEl(null);

    const [anchorLangEl, setAnchorLangEl] = React.useState(null);

    const switcher = [
        context.theme === 'net' ? (
            <Button
                key="net"
                component="span"
                sx={theme => ({
                    height: 54,
                    fontSize: '1rem',
                    textAlign: 'center',
                    ...theme.topMenu[props.style].active,
                })}
                fullWidth={width < MOBILE_WIDTH}
            >
                <ListItemText primary={__('net')} />
            </Button>
        ) : (
            <Button
                key="net"
                component="a"
                href="https://iobroker.net/www/"
                sx={theme => ({
                    height: 54,
                    fontSize: '1rem',
                    textAlign: 'center',
                    color: theme.topMenu[props.style].color,
                })}
                fullWidth={width < MOBILE_WIDTH}
                onClick={_handleClose}
            >
                <ListItemText primary={__('net')} />
            </Button>
        ),
        context.theme !== 'net' ? (
            <Button
                key="pro"
                component="span"
                sx={theme => ({
                    height: 54,
                    fontSize: '1rem',
                    textAlign: 'center',
                    ...theme.topMenu[props.style].active,
                })}
                fullWidth={width < MOBILE_WIDTH}
            >
                <ListItemText primary={__('pro')} />
            </Button>
        ) : (
            <Button
                key="pro"
                component="a"
                href="https://iobroker.pro/www/"
                sx={theme => ({
                    height: 54,
                    fontSize: '1rem',
                    textAlign: 'center',
                    color: theme.topMenu[props.style].color,
                })}
                fullWidth={width < MOBILE_WIDTH}
                onClick={_handleClose}
            >
                <ListItemText primary={__('pro')} />
            </Button>
        ),
    ];

    const _menuMain = Object.keys(menuMain).map((menuKey, key) => {
        const menuItem = menuMain[menuKey];
        return menuItem.url ? (
            <Button
                component="a"
                href={menuItem.url}
                key={key}
                sx={theme => ({
                    height: 54,
                    fontSize: '1rem',
                    textAlign: 'center',
                    color: theme.topMenu[props.style].color,
                })}
                fullWidth={width < MOBILE_WIDTH}
                onClick={_handleClose}
            >
                <ListItemText primary={__(menuMain[menuKey].title)} />
            </Button>
        ) : (
            <Button
                component={NavLink}
                to={`/www/${menuKey}`}
                key={key}
                sx={theme => ({
                    height: 54,
                    fontSize: '1rem',
                    textAlign: 'center',
                    color: theme.topMenu[props.style].color,
                    '&.MuiButton-active': {
                        ...theme.topMenu[props.style].active,
                    },
                })}
                fullWidth={width < MOBILE_WIDTH}
                onClick={_handleClose}
            >
                <ListItemText primary={__(menuMain[menuKey].title)} />
            </Button>
        );
    });

    const loginButton =
        context.user && context.user.userId ? null : (
            <Button
                component={NavLink}
                to="/www/login"
                fullWidth={width < MOBILE_WIDTH}
                title={width < SMALL_MOBILE_WIDTH ? __('Login') : ''}
                sx={theme => ({
                    ...styles.menuButton,
                    color: theme.topMenu[props.style].color,
                    '&.MuiButton-active': {
                        ...theme.topMenu[props.style].active,
                    },
                })}
                onClick={_handleClose}
            >
                <ListItemIcon
                    style={{
                        color: context.theme === 'net' ? '#000' : '#FFF',
                        minWidth: 24,
                    }}
                >
                    <IconPerson />
                </ListItemIcon>
                {width >= SMALL_MOBILE_WIDTH ? <ListItemText primary={__('Login')} /> : null}
            </Button>
        );

    const menuForm =
        width > MOBILE_WIDTH ? (
            <Grid item>
                {switcher}
                {_menuMain}
                {loginButton}
            </Grid>
        ) : null;

    const hamburger =
        width <= MOBILE_WIDTH ? (
            <Grid
                item
                style={styles.hamburger}
            >
                {loginButton}
                <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    color="primary"
                    onClick={_handleClick}
                >
                    <IconMenu />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={_anchorEl}
                    keepMounted
                    open={Boolean(_anchorEl)}
                    onClose={_handleClose}
                    sx={{ '&.MuiMenu-paper': theme => theme.mobileMenu[props.style] }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {switcher}
                    {_menuMain}
                </Menu>
            </Grid>
        ) : null;

    let logo;

    if (context.theme === 'net') {
        logo = width < SMALL_MOBILE_WIDTH ? logoNetSmall : logoNet;
    } else {
        logo = width < SMALL_MOBILE_WIDTH ? logoProSmall : logoPro;
    }

    function switchLang(lang) {
        context.setLang(lang);
        setAnchorLangEl(null);
    }

    const langButton =
        !context.user || !context.user.userId ? (
            <>
                <Button
                    style={{
                        ...styles.menuButton,
                        textAlign: 'center',
                        ...styles.language,
                    }}
                    onClick={e => setAnchorLangEl(e.currentTarget)}
                >
                    <IconLanguage style={styles.buttonIcon} />
                    {__(context.lang)}
                </Button>
                {anchorLangEl ? (
                    <Menu
                        anchorEl={anchorLangEl}
                        keepMounted
                        open={!0}
                        onClose={() => setAnchorLangEl(null)}
                    >
                        <MenuItem onClick={() => switchLang('de')}>Deutsch</MenuItem>
                        <MenuItem onClick={() => switchLang('en')}>English</MenuItem>
                        <MenuItem onClick={() => switchLang('ru')}>русский</MenuItem>
                    </Menu>
                ) : null}
            </>
        ) : null;

    return (
        <Grid
            container
            sx={theme => ({
                zIndex: 1201,
                ...theme.topMenu[props.style],
            })}
        >
            <Grid
                item
                style={styles.title}
            >
                <Button
                    component={NavLink}
                    style={styles.menuButton}
                    to="/www/"
                >
                    <img
                        style={styles.logo}
                        src={logo}
                        height={logoHeight}
                        width={logoWidth}
                        alt="logo"
                    />
                </Button>
                {langButton}
            </Grid>
            <Grid
                item
                style={{ flexGrow: 1 }}
            />
            {menuForm}
            {hamburger}
        </Grid>
    );
}
