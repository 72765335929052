import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Typography, FormControl, InputLabel, Input, Button, CircularProgress } from '@mui/material';
import { Check as IconCheck } from '@mui/icons-material';

import { apiRequestResetPasswordEmail } from '../../api';

import MainContext from '../../MainContext';

import __ from '../../utils/i18n';
import { MOBILE_WIDTH } from '../../utils/consts';

const styles = {
    btn: {
        marginTop: 20,
    },
};

export default function ForgotPassword(props) {
    const navigate = useNavigate();
    const context = React.useContext(MainContext);

    const [email, setEmail] = React.useState('');
    const [sending, setSending] = React.useState(false);

    const handleEmail = event => setEmail(event.target.value);

    const handleSentPassword = () => {
        setSending(true);
        apiRequestResetPasswordEmail(email)
            .then(() => {
                context.snackbar(__('Instructions sent to %s', email), 'success', true);
                setTimeout(() => navigate('/www/login'), 2000);
            })
            .catch(error => context.snackbar(error, 'error'));
    };

    return (
        <Grid
            container
            alignContent="center"
            alignItems="center"
            direction="column"
            sx={theme => ({
                ...theme.container[props.style],
                padding: window.document.body.clientWidth >= MOBILE_WIDTH ? '10px 50px' : '5px 5px',
            })}
        >
            <Typography
                paragraph
                sx={theme => theme.containerTitle[props.style]}
            >
                {__('Forgot password?')}
            </Typography>
            <Typography paragraph>{__('No problem! Just enter your email and we will')}</Typography>
            <Typography paragraph>{__('send you the instructions how to change a password')}</Typography>
            <div>
                <FormControl variant="standard">
                    <InputLabel htmlFor="email">{__('Your email')}</InputLabel>
                    <Input
                        id="email"
                        value={email}
                        onChange={handleEmail}
                        aria-describedby="emailText"
                        onKeyDown={e => e.key === 'Enter' && email && handleSentPassword()}
                    />
                </FormControl>
            </div>
            <div>
                <Button
                    disabled={!email || sending}
                    variant="contained"
                    style={styles.btn}
                    color="primary"
                    startIcon={<IconCheck />}
                    onClick={handleSentPassword}
                >
                    {sending ? <CircularProgress size={20} /> : null}
                    {__('Send email')}
                </Button>
            </div>
        </Grid>
    );
}
