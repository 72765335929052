import React from 'react';

import { Typography, Grid } from '@mui/material';
import __ from '../../utils/i18n';

export default function TryAgainConfirm() {
    return (
        <Grid
            container
            alignContent="center"
            alignItems="center"
            direction="column"
        >
            <Typography paragraph>
                <p>{__('Unfortunately we cannot confirm your email or email is already confirmed.')}</p>
                <p>{__('You can send confirmation email anew from your profile.')}</p>
            </Typography>
        </Grid>
    );
}
