import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import {
    Grid2 as Grid,
    Card,
    Button,
    Typography,
    Checkbox,
    Modal,
    Backdrop,
    Fade,
    FormControl,
    Input,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Select,
    InputLabel,
    MenuItem,
} from '@mui/material';

import { Close as IconClose, Check as IconCheck } from '@mui/icons-material';

import MainContext, { ContextConsumer, CLOUD } from '../../MainContext';
import ContractDialog from './ContractDialog';
import __ from '../../utils/i18n';
import { MOBILE_WIDTH } from '../../utils/consts';
import PayPalButton from './PaypalButton';
import { apiCreateLicense, apiGetCountries } from '../../api';

const ADDRESS_FIELDS = [
    { name: 'Name', required: true, maxLen: 64 },
    { name: 'FirmName', required: false, maxLen: 32 },
    { name: 'AddressLine1', required: true, maxLen: 64 },
    { name: 'AddressLine2', required: false, maxLen: 64 },
    [
        {
            name: 'ZIP',
            required: true,
            width: 4,
            maxLen: 8,
        },
        {
            name: 'City',
            required: true,
            width: 8,
            maxLen: 64,
        },
    ],
    { name: 'Country', required: true, maxLen: 64 },
    { name: 'OrderComment', required: false, maxLen: 64 },
];

const styles = {
    title: theme => ({
        fontSize: '1.4rem',
        ...theme.priceCard[CLOUD].title,
    }),
    productName: {
        fontWeight: 'bold',
        marginLeft: 8,
    },
    check: {
        padding: 0,
        margin: 0,
        marginRight: 10,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: theme => ({
        position: 'absolute',
        width:
            window.document.body.clientWidth >= MOBILE_WIDTH
                ? 500
                : window.document.body.clientWidth - parseInt(theme.spacing(4), 10),
        backgroundColor: theme.palette.background.paper,
        border: '2px solid transparent',
        boxShadow: theme.shadows[5],
        padding: window.document.body.clientWidth >= MOBILE_WIDTH ? theme.spacing(2, 4, 3) : '8px',
        outline: 'none',
    }),
    cnc: {
        display: 'flex',
        flexDirection: 'column',
    },
    cncBtn: {
        marginTop: 8,
        minWidth: 200,
    },
    label: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 11,
    },
    notReady: {
        opacity: 0.3,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    progress: {
        opacity: '0.2 !important',
        userSelect: 'none',
        pointerEvents: 'none',
    },
    formControl: {
        width: '100%',
        textAlign: 'left',
        marginBottom: 8,
    },
    vat: {
        fontSize: 12,
        marginLeft: 8,
    },
    space: {
        marginLeft: 8,
    },
    spaces: {
        marginLeft: 8,
        marginRight: 8,
    },
};

class ModalWindow extends Component {
    static contextType = MainContext;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            error: '',
            firefox: false,
            success: false,
            paypalReady: false,
            billing: !props.product || !props.product.price,
            address: {},
            progress: false,
            addressOk: true,
            showContract: false,
            countries: null,
            uuid: '',
            uuidOk: !this.props.uuid,
        };

        this.mobile = window.document.body.clientWidth < MOBILE_WIDTH;
    }

    componentDidMount() {
        apiGetCountries().then(countries => this.setState({ countries }));
    }

    handleOpen = context => {
        if (context.user) {
            this.setState({ open: true });
        } else {
            context.setLoginRedirect('/www/pricing');
            this.props.navigate('/www/login');
        }
    };

    handleClose = () => !this.state.progress && this.setState({ open: false });

    handleBilling = () => {
        const newBilling = !this.state.billing;
        const addressOk = this.isAddressOk(null, newBilling);

        this.setState({ billing: newBilling, addressOk });
    };

    isAddressOk(address, billing) {
        address = address || this.state.address;
        billing = billing === undefined || billing === null ? this.state.billing : billing;

        const _address = JSON.parse(JSON.stringify(address));

        if (!billing && _address.country === 'Germany') {
            return true;
        }

        return !ADDRESS_FIELDS.find(item => {
            if (Array.isArray(item)) {
                return item.find(
                    subitem =>
                        (subitem.required && !_address[subitem.name]) ||
                        (_address[subitem.name] && _address[subitem.name].length > subitem.maxLen),
                );
            }

            return (
                (item.required && !_address[item.name]) ||
                (_address[item.name] && _address[item.name].length > item.maxLen)
            );
        });
    }

    renderInput(item) {
        const value = this.state.address[item.name] || '';

        return (
            <FormControl
                key={item.name}
                style={{ width: '100%' }}
                variant="standard"
            >
                <Input
                    id={item.name}
                    disabled={item.name === 'Country' && this.state.address.country !== 'Other'}
                    aria-describedby={item.name}
                    error={this.state.address[item.name] ? this.state.address[item.name].length > item.maxLen : false}
                    title={
                        this.state.address[item.name] && this.state.address[item.name].length > item.maxLen
                            ? __('Text is too long. Allowed only %s characters', item.maxLen)
                            : ''
                    }
                    value={value}
                    type={item.type || 'text'}
                    placeholder={__(item.name) + (item.required ? ' *' : '')}
                    onChange={evt => {
                        const address = { ...this.state.address };
                        address[item.name] = evt.currentTarget.value;

                        const addressOk = this.isAddressOk(address);

                        this.setState({ address, addressOk });
                    }}
                />
            </FormControl>
        );
    }

    renderBillingControls() {
        if (!this.state.address.country) {
            return null;
        }

        return (this.state.billing && this.state.address.country === 'Germany') ||
            this.state.address.country !== 'Germany'
            ? ADDRESS_FIELDS.map((field, i) => {
                  if (Array.isArray(field)) {
                      return (
                          <Grid
                              container
                              key={`name${i}`}
                              spacing="1px"
                          >
                              {field.map(subfield => (
                                  <Grid
                                      xs={subfield.width || 6}
                                      key={subfield.name}
                                  >
                                      {this.renderInput(subfield)}
                                  </Grid>
                              ))}
                          </Grid>
                      );
                  }

                  return this.renderInput(field);
              })
            : null;
    }

    renderUuid() {
        if (this.props.uuid) {
            return (
                <Grid
                    container
                    key="uuid"
                    spacing="1px"
                >
                    <Grid xs={12}>
                        <FormControl
                            style={{ width: '100%' }}
                            variant="standard"
                        >
                            <Input
                                id="uuid"
                                error={!this.state.uuidOk}
                                title={__(
                                    'You can find serial number in vis configuration page (from vis version 1.4.2) in admin or by writing in CLI "iob uuid"',
                                )}
                                value={this.state.uuid}
                                type="text"
                                placeholder={`${__('Serial number (UUID)')} *`}
                                onChange={evt => {
                                    const uuid = evt.currentTarget.value.trim();
                                    // aaaabbbb-xxxx-yyyy-zzzz-zzzzzzzzzzzz
                                    const uuidOk =
                                        uuid &&
                                        uuid.includes('-') &&
                                        uuid.length >= 'aaaabbbb-xxxx-yyyy-zzzz-zzzzzzzzzzzz'.length &&
                                        uuid.length <= '123aaaabbbb-xxxx-yyyy-zzzz-zzzzzzzzzzzz'.length;

                                    this.setState({ uuid, uuidOk });
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            );
        }

        return null;
    }

    renderErrorDialog() {
        return this.state.error ? (
            <Dialog
                open={!0}
                onClose={() => this.setState({ error: '', firefox: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{__('Error')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.firefox
                            ? __(
                                  'It looks like you are using Firefox. Paypal has a problem with this. Please try chrome',
                              )
                            : null}
                        {this.state.firefox ? <br /> : null}
                        {this.state.error}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.setState({ error: '', firefox: false })}
                        color="primary"
                        autoFocus
                        startIcon={<IconClose />}
                    >
                        {__('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
        ) : (
            ''
        );
    }

    renderSuccessDialog(context) {
        return this.state.success ? (
            <Dialog
                open={!0}
                onClose={() => {
                    this.setState({ success: false });
                    if (context.theme === 'pro') {
                        this.props.navigate('/www/account/subscriptions');
                    } else {
                        this.props.navigate('/www/account/licenses');
                    }
                }}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
            >
                <DialogTitle id="success-dialog-title">{__('Success!')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="success-dialog-description">
                        {__(
                            context.theme === 'pro'
                                ? 'You can find your new license in subscriptions'
                                : 'You can find your new license in licenses',
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            this.setState({ success: false });
                            this.props.navigate(
                                `/www/account/${context.theme === 'pro' ? 'subscriptions' : 'licenses'}`,
                            );
                        }}
                        startIcon={<IconCheck />}
                        color="primary"
                        autoFocus
                    >
                        {__('Ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        ) : (
            ''
        );
    }

    renderContractDialog() {
        if (!this.state.showContract) {
            return null;
        }

        return <ContractDialog onClose={() => this.setState({ showContract: false })} />;
    }

    renderPaypalButton(context, vat) {
        if (
            !this.props.product ||
            !this.props.product.price ||
            !context.user ||
            !context.user.contractAck ||
            !this.state.address.country ||
            this.state.address.disabled ||
            !this.state.uuidOk
        ) {
            return null;
        }
        return (
            <PayPalButton
                product={this.props.product}
                vat={vat}
                uuid={this.state.uuid}
                address={this.state.address}
                countries={this.state.countries}
                cloud={context.theme}
                onProgress={(progress, cb) => this.setState({ progress }, () => cb && cb())}
                onFinish={(state, cb) =>
                    this.setState(state, () => {
                        this.props.onGenerated && this.props.onGenerated();
                        cb && cb();
                    })
                }
                onReady={cb => this.setState({ paypalReady: true }, () => cb && cb())}
            />
        );
    }

    renderPrivateLicenseButton(context) {
        if (!context.user || !context.user.contractAck) {
            return null;
        }
        if (this.props.product && !this.props.product.price) {
            return (
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                        const address = JSON.parse(JSON.stringify(this.state.address));
                        delete address.disabled;
                        delete address.eu;

                        apiCreateLicense({
                            comment: address.comment || '',
                            address,
                            product: this.props.product.name,
                        })
                            .then(data => {
                                if (data && data.error) {
                                    this.setState({
                                        progress: false,
                                        error: `${__('Cannot generate license. Please contact support info@iobroker.net')}: ${data.error}`,
                                    });
                                } else {
                                    this.setState(
                                        {
                                            progress: false,
                                            open: false,
                                            success: true,
                                        },
                                        () => this.props.onGenerated && this.props.onGenerated(),
                                    );
                                }
                            })
                            .catch(e => {
                                this.setState({
                                    progress: false,
                                    error: `${__('Cannot generate license. Please contact support info@iobroker.net')}: ${e.error}`,
                                });
                            });
                    }}
                    color="primary"
                >
                    {__('Get private license')}
                </Button>
            );
        }

        return null;
    }

    render() {
        let vat = null;
        if (this.state.address.country && this.state.countries && this.props.product?.price) {
            vat = this.state.countries[this.state.address.country].vat;
            if (vat) {
                const netto = Math.ceil((this.props.product.price * 10000) / (100 + vat));
                vat = ((this.props.product.price * 100 - netto) / 100).toFixed(2).replace('.', '.');
            }
        }
        if (!this.state.countries) {
            return <CircularProgress />;
        }

        return (
            <ContextConsumer>
                {context => {
                    if (!this.props.product) {
                        return (
                            <Button
                                variant="contained"
                                // color="default"
                                onClick={() =>
                                    context.snackbar(
                                        'Currently there is no such option. Please order new subscription.',
                                        'error',
                                    )
                                }
                            >
                                {__('Re-order not possible')}
                            </Button>
                        );
                    }

                    return (
                        <Fragment key={this.props.product ? this.props.product.name : ''}>
                            {this.renderErrorDialog()}
                            {this.renderSuccessDialog(context)}
                            {this.renderContractDialog()}
                            <Button
                                variant="contained"
                                color={!(this.props.product && this.props.product.best) ? 'primary' : undefined}
                                // style={this.props.style}
                                onClick={() => this.handleOpen(context)}
                                disabled={!this.props.orderEnabled}
                            >
                                {this.props.label ? this.props.label : __('Order Now')}
                            </Button>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                style={styles.modal}
                                open={this.state.open || this.state.progress}
                                onClose={() => this.handleClose()}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{ timeout: 500 }}
                            >
                                <Fade in={this.state.open || this.state.progress}>
                                    <Card
                                        style={this.state.progress ? styles.progress : undefined}
                                        sx={styles.paper}
                                    >
                                        <Typography
                                            gutterBottom
                                            sx={styles.title}
                                            component="div"
                                        >
                                            {context.theme === 'net'
                                                ? __('Order new license')
                                                : __('Order new subscription')}
                                            :
                                            <span style={styles.productName}>
                                                {this.props.product ? __(this.props.product.name) : '__'}
                                            </span>
                                            <br />
                                            {/* this.props.product.months ? (this.props.product.months === 1 ? __('for 1 month', this.props.product.months) : __('for %s months', this.props.product.months)) : '' */}
                                            {__('for_')}
                                            <span style={styles.space}>
                                                {this.props.product && this.props.product.price}
                                            </span>
                                            €
                                            {vat !== null ? (
                                                <span style={styles.vat}>
                                                    ({vat ? `${__('incl. VAT')} ${vat}€` : __('no VAT')})
                                                </span>
                                            ) : null}
                                        </Typography>
                                        <Grid
                                            container
                                            spacing="2px"
                                        >
                                            <Grid xs={this.mobile ? 12 : 6}>
                                                {this.renderUuid()}
                                                <FormControl
                                                    style={styles.formControl}
                                                    fullWidth
                                                    variant="standard"
                                                >
                                                    <InputLabel id="country-select-label">
                                                        {__('Country of residence')}
                                                    </InputLabel>
                                                    <Select
                                                        variant="standard"
                                                        labelId="country-select-label"
                                                        id="country-select"
                                                        value={this.state.address.country || ''}
                                                        onChange={e => {
                                                            const address = JSON.parse(
                                                                JSON.stringify(this.state.address),
                                                            );
                                                            address.country = e.target.value;
                                                            address.eu = this.state.countries[address.country].eu;
                                                            address.disabled =
                                                                this.state.countries[address.country].enabled === false;
                                                            address.Country =
                                                                address.country !== 'Other' ? __(address.country) : '';
                                                            const addressOk = this.isAddressOk(address);
                                                            if (address.disabled) {
                                                                this.setState({
                                                                    address,
                                                                    addressOk: false,
                                                                    error: __(
                                                                        'Unfortunately we have no possibility to sell our product in your country, because of complex VAT transactions',
                                                                    ),
                                                                });
                                                            } else {
                                                                this.setState({ address, addressOk });
                                                            }
                                                        }}
                                                    >
                                                        {Object.keys(this.state.countries).map(country => (
                                                            <MenuItem
                                                                key={country}
                                                                value={country}
                                                                style={{
                                                                    fontWeight:
                                                                        country === 'Other' ? 'bold' : 'inherit',
                                                                }}
                                                            >
                                                                {__(country)}
                                                                <span style={styles.spaces}>-</span>
                                                                {this.state.countries[country].eu
                                                                    ? '(EU)'
                                                                    : __('(non EU)')}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {!this.state.address.disabled &&
                                                this.state.address.country === 'Germany' &&
                                                this.props.product.price ? (
                                                    <Checkbox
                                                        checked={this.state.billing}
                                                        edge="start"
                                                        style={styles.check}
                                                        onChange={() => this.handleBilling()}
                                                        id="billing"
                                                    />
                                                ) : null}
                                                {!this.state.address.disabled &&
                                                this.state.address.country === 'Germany' &&
                                                this.props.product.price ? (
                                                    <label
                                                        htmlFor="billing"
                                                        style={styles.label}
                                                    >
                                                        {__('Billing address (optional)')}
                                                    </label>
                                                ) : null}
                                                {this.renderBillingControls()}
                                            </Grid>
                                            <Grid
                                                xs={this.mobile ? 12 : 6}
                                                style={{
                                                    ...styles.cnc,
                                                    ...(context.user &&
                                                    context.user.contractAck &&
                                                    this.props.product &&
                                                    (!this.state.addressOk ||
                                                        (!this.state.paypalReady && this.props.product.price) ||
                                                        !this.state.address.country)
                                                        ? styles.notReady
                                                        : undefined),
                                                }}
                                            >
                                                {!this.state.address.disabled &&
                                                context.user &&
                                                !context.user.contractAck ? (
                                                    <Button
                                                        onClick={() => this.setState({ showContract: true })}
                                                        color="primary"
                                                        variant="contained"
                                                        style={styles.cncBtn}
                                                    >
                                                        {__('Please accept contract')}
                                                    </Button>
                                                ) : null}
                                                {context.user &&
                                                context.user.contractAck &&
                                                this.props.product &&
                                                this.props.product.price &&
                                                this.state.address.country &&
                                                (!this.state.paypalReady || this.state.progress) ? (
                                                    <CircularProgress />
                                                ) : null}
                                                {this.renderPaypalButton(context, vat)}
                                                {this.renderPrivateLicenseButton(context)}
                                            </Grid>
                                            <Grid xs={12}>
                                                <Grid
                                                    container
                                                    spacing="2px"
                                                    alignContent="flex-end"
                                                >
                                                    <Grid xs={6} />
                                                    <Grid xs={6}>
                                                        <Button
                                                            fullWidth
                                                            // color="default"
                                                            style={styles.cncBtn}
                                                            variant="contained"
                                                            onClick={() => this.handleClose()}
                                                            startIcon={<IconClose />}
                                                        >
                                                            {__('Cancel')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Fade>
                            </Modal>
                        </Fragment>
                    );
                }}
            </ContextConsumer>
        );
    }
}

ModalWindow.propTypes = {
    product: PropTypes.object,
    style: PropTypes.object,
    orderEnabled: PropTypes.bool,
    label: PropTypes.string,
    onGenerated: PropTypes.func,
    navigate: PropTypes.func,
    uuid: PropTypes.bool,
};

export default ModalWindow;
