import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Button,
    FormControl,
    InputLabel,
    Input,
    Grid,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

import { Close as IconClose, Delete as IconDelete } from '@mui/icons-material';

import MainContext from '../../../MainContext';
import { apiDeleteUser, apiReadLicenses, apiReadSubscriptions } from '../../../api';
import __ from '../../../utils/i18n';
import { MOBILE_WIDTH } from '../../../utils/consts';

const styles = {
    btn: {
        marginTop: 20,
        marginRight: 10,
    },
    email: {
        minWidth: 300,
    },
};

export default function DeleteProfile(props) {
    const context = React.useContext(MainContext);
    const navigate = useNavigate();
    const [email, setEmail] = React.useState('');
    const [confirmDelete, setConfirmDelete] = React.useState(false);

    const handleEmail = event => setEmail(event.target.value);

    const deleteUser = () => {
        apiDeleteUser()
            .then(() => {
                context.snackbar('User deleted', 'success');
                context.logoutUser().then(() => navigate('/www/'));
            })
            .catch(error => context.snackbar(__('Cannot delete user: %s', __(error)), 'error', true));
    };

    const handleDeleteProfile = (/* event */) => {
        if (email !== context.user.email) {
            context.snackbar('Wrong email', 'error');
            return;
        }
        let promise;
        if (context.theme === 'net') {
            promise = apiReadLicenses().then(result => result && result.find(item => item.invoice !== 'free'));
        } else {
            const now = new Date().toISOString();
            promise = apiReadSubscriptions().then(result => result && result.find(item => item.validTill > now));
        }

        // read current licenses
        promise
            .catch(e => {
                if (e === 'Invalid user or email not acknowledged') {
                    return false;
                }
                return Promise.reject(e);
            })
            .then(areSomeNonFreeLicenses => {
                if (areSomeNonFreeLicenses) {
                    setConfirmDelete(true);
                } else {
                    deleteUser();
                }
            })
            .catch(error => context.snackbar(__('Cannot read user licenses: %s', __(error)), 'error', true));
    };

    const confirmDeleteDialog = confirmDelete ? (
        <Dialog
            open={!0}
            onClose={() => setConfirmDelete(false)}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
        >
            <DialogTitle id="confirm-dialog-title">{__('Are you really want to delete?')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {__(
                        'There are some commercial licenses found. They all will be deleted too and cannot be restored.',
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setConfirmDelete(false)}
                    color="primary"
                    autoFocus
                    startIcon={<IconClose />}
                >
                    {__('Cancel')}
                </Button>
                <Button
                    variant="contained"
                    startIcon={<IconDelete />}
                    onClick={() => {
                        setConfirmDelete(false);
                        deleteUser();
                    }}
                >
                    {__('Delete with all licenses')}
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;

    const handleCancel = () => navigate('/www/account/profile');

    return (
        <Grid
            container
            alignContent="center"
            alignItems="center"
            direction="column"
            sx={theme => ({
                ...theme.container[props.style],
                padding: window.document.body.clientWidth >= MOBILE_WIDTH ? '5px 5px' : '10px 50px',
            })}
        >
            <Typography
                paragraph
                sx={theme => theme.containerTitle[props.style]}
            >
                {__('Delete profile?')}
            </Typography>
            <Typography paragraph>{__('Please write your email for confirm:')}</Typography>
            <FormControl
                style={styles.email}
                variant="standard"
            >
                <InputLabel htmlFor="email">{__('Email')}</InputLabel>
                <Input
                    value={email}
                    onChange={handleEmail}
                    inputProps={{
                        autoComplete: 'new-password',
                        form: { autoComplete: 'off' },
                    }}
                    onKeyDown={e => e.key === 'Enter' && handleDeleteProfile()}
                    aria-describedby="emailText"
                />
            </FormControl>
            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={!email}
                    style={styles.btn}
                    onClick={handleDeleteProfile}
                    startIcon={<IconDelete />}
                >
                    {__('Delete Profile')}
                </Button>
                <Button
                    variant="contained"
                    startIcon={<IconDelete />}
                    style={styles.btn}
                    onClick={handleCancel}
                >
                    {__('Cancel')}
                </Button>
            </div>
            {confirmDeleteDialog}
        </Grid>
    );
}
