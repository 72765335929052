import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { FormControl, TextField, Grid2 as Grid, Button, Checkbox } from '@mui/material';

import { apiLogin } from '../../api';
import MainContext from '../../MainContext';
import __ from '../../utils/i18n';
import PasswordInput from '../../utils/PasswordInput';

const styles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
    },
    margin: {
        margin: 8,
    },
    withoutLabel: {
        marginTop: 24,
    },
    textField: {
        width: '30ch',
    },
    forgot: {
        textTransform: 'lowercase',
        fontSize: 10,
    },
    label: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 11,
    },
};

export default function LoginForm() {
    const context = React.useContext(MainContext);
    const passRef = React.createRef();

    const [values, setValues] = React.useState({
        login: '',
        password: '',
        showPassword: false,
        remember: false,
    });

    const handleChange = prop => event => {
        if (event.target.type === 'checkbox') {
            setValues({ ...values, [prop]: event.target.checked });
        } else {
            setValues({ ...values, [prop]: event.target.value });
        }
    };

    const handleLogin = () =>
        apiLogin(values.login, values.password, values.remember)
            .then(() => context.updateUser(true))
            .then(() => context.snackbar('You are logged in', 'success')) // redirect will be done in Login/index.js
            .catch(e => {
                console.error(`Cannot login: ${e}`);
                context.snackbar(e, 'error');
            });

    return (
        <div style={styles.root}>
            <Grid
                container
                direction="column"
                alignContent="center"
                alignItems="center"
            >
                <form style={styles.form}>
                    <TextField
                        variant="standard"
                        id="login"
                        style={{
                            ...styles.margin,
                            ...styles.textField,
                        }}
                        value={values.login}
                        placeholder={__('login')}
                        onChange={handleChange('login')}
                    />
                    <PasswordInput
                        style={{
                            ...styles.margin,
                            ...styles.textField,
                        }}
                        inputRef={passRef}
                        id="password"
                        value={values.password}
                        placeholder={__('password')}
                        onChange={password => setValues({ ...values, password })}
                        onKeyDown={e => e.key === 'Enter' && handleLogin()}
                    />
                    <FormControl
                        style={{ display: 'block' }}
                        variant="standard"
                    >
                        <Checkbox
                            checked={values.remember}
                            onChange={handleChange('remember')}
                            id="privacyAck"
                        />
                        <label
                            htmlFor="remember"
                            style={styles.label}
                            onClick={() => setValues({ ...values, remember: !values.remember })}
                        >
                            {__('Remember me')}
                        </label>
                    </FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleLogin}
                        disabled={!values.login || !values.password}
                    >
                        {__('Sign In')}
                    </Button>
                    <FormControl
                        style={{
                            ...styles.margin,
                            ...styles.textField,
                        }}
                        variant="standard"
                    >
                        <Button
                            size="small"
                            component={RouterLink}
                            to="/www/forgotPassword"
                            sx={{ '& .MuiButton-text': styles.forgot, fontSize: 10 }}
                        >
                            {__('Forgot password')}
                        </Button>
                    </FormControl>
                </form>
            </Grid>
        </div>
    );
}
